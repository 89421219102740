<!--
 * @Description: 选择更换设备弹框(新)
 * @Author: ChenXueLin
 * @Date: 2021-11-22 11:57:20
 * @LastEditTime: 2022-06-29 15:24:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="选择设备"
      :visible.sync="changeEquipVisible"
      width="900px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="fee-content">
        <!-- 搜索头部 start -->
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item
            class="search-item--1"
            prop="materialNo"
            v-if="basicInfo.taskSecondClass == 10"
          >
            <e6-vr-select
              v-model="searchForm.materialNo"
              :data="equipRow.materialNos"
              placeholder="物料名称"
              title="物料名称"
              :props="{
                id: 'materialNo',
                label: 'materialName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item
            class="search-item--1"
            prop="equipNameOrDeviceNumber"
            v-if="equipRow.isBatchManage"
          >
            <el-input
              v-model="searchForm.equipNameOrDeviceNumber"
              placeholder="设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <!-- <el-button class="reset" @click="handleReset">重置</el-button> -->
          </el-form-item>
        </el-form>
        <!-- 搜索头部 end -->
        <template>
          <el-table
            border
            :data="equipList"
            highlight-current-row
            @select="select"
            @select-all="selectAll"
            @selection-change="handleDeleteionChange"
            ref="multipleTable"
          >
            <el-table-column
              type="selection"
              width="50"
              fixed
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
              <template slot-scope="{ row }">
                <span
                  v-if="column.fieldName == 'equipNo' && equipRow.isBatchManage"
                >
                  {{ row.equipNo }}
                </span>
                <span v-else>{{ row[column.fieldName] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- 分页 start -->
        <section
          class="pagination-wrapper fixed-section"
          ref="paginationWrapper"
        >
          <el-pagination
            :page-size.sync="searchForm.pageSize"
            :current-page.sync="searchForm.pageIndex"
            :page-sizes="pageSizes"
            :layout="layout"
            :total="total"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </section>
        <!-- 分页 end -->
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import {
  queryEngineerAllEquip,
  getClientAllEquip,
  getCustormerEquip
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "newChangeEquip",
  components: {},
  data() {
    return {
      equipList: [],
      total: 0,
      searchForm: {
        equipNameOrDeviceNumber: "",
        equipCode: "", //设备编号
        materialNo: "",
        materialNos: [],
        corpId: this.basicInfo.corpId,
        equipSource: 2, // 2:工程师1：客户3：项目
        pageIndex: 1,
        pageSize: 10
      },
      dialogLoading: false,
      total1: 0,
      total2: 0,
      total3: 0,
      equipSourceList: [], //设备来源
      selColumn: [], //选中的数据
      projectEquipList: [], //项目下设备
      engineerEquipList: [], //工程师名下设备
      custormerEquipList: [] //客户名下设备
    };
  },
  //安装单：taskSecondClass：1，2
  //isDeliverInstall:（1送装，2寄装）
  props: ["basicInfo", "changeEquipVisible", "equipRow", "applyNo"],
  mixins: [base, listPage],
  computed: {
    columnData() {
      let data =
        this.basicInfo.isDeliverInstall != 2
          ? [
              {
                fieldName: "isNewString",
                display: true,
                fieldLabel: "新旧状态",
                width: 100,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "thirdClassName",
                display: true,
                fieldLabel: "商品名称(三级分类)",
                width: 160,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "companyName",
                display: true,
                fieldLabel: "发货组织",
                width: 140,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "materialName",
                display: true,
                fieldLabel: "物料名称",
                width: 160,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "materialNo",
                display: true,
                fieldLabel: "物料编码",
                width: 120,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "equipNo",
                display: true,
                fieldLabel: "设备编号",
                width: 140,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "stock",
                display: true,
                fieldLabel: "设备数量",
                width: 120,
                disabled: false,
                fixed: false,
                align: "center"
              }
            ]
          : this.equipRow.isBatchManage
          ? [
              {
                fieldName: "isNewString",
                display: true,
                fieldLabel: "新旧状态",
                width: 100,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "thirdClassName",
                display: true,
                fieldLabel: "商品名称(三级分类)",
                width: 160,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "materialName",
                display: true,
                fieldLabel: "物料名称",
                width: 160,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "materialNo",
                display: true,
                fieldLabel: "物料编码",
                width: 120,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "equipNo",
                display: true,
                fieldLabel: "设备编号",
                width: 140,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "stock",
                display: true,
                fieldLabel: "设备数量",
                width: 120,
                disabled: false,
                fixed: false,
                align: "center"
              }
            ]
          : [
              {
                fieldName: "isNewString",
                display: true,
                fieldLabel: "新旧状态",
                width: 100,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "thirdClassName",
                display: true,
                fieldLabel: "商品名称(三级分类)",
                width: 160,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "materialName",
                display: true,
                fieldLabel: "物料名称",
                width: 160,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "materialNo",
                display: true,
                fieldLabel: "物料编码",
                width: 120,
                disabled: false,
                fixed: false,
                align: "center"
              },
              {
                fieldName: "stock",
                display: true,
                fieldLabel: "设备数量",
                width: 120,
                disabled: false,
                fixed: false,
                align: "center"
              }
            ];
      return data;
    }
  },
  watch: {
    changeEquipVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchForm.corpId = this.basicInfo.corpId;
          this.searchForm.materialNos =
            this.basicInfo.taskSecondClass == 10
              ? this.equipRow.materialNos.map(item => item.materialNo)
              : [this.equipRow.materialNo];

          this.$nextTick(() => {
            this.queryList();
          });
        }
      }
    }
  },
  mounted() {},
  methods: {
    checkSelectable(row) {
      let flag = false;
      if (!this.searchForm.equSource && row.isAvailable == 0) {
        flag = false;
      } else {
        flag = true;
      }
      return flag;
    },
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.searchForm.materialNos = this.searchForm.materialNo
        ? [this.searchForm.materialNo]
        : this.searchForm.materialNos;
      this.equipList = [];
      this.queryList();
    },
    //获取客户名下设备
    async getCustormerEquipReq() {
      try {
        this.dialogLoading = true;
        let res = await getCustormerEquip({
          corpId: this.basicInfo.corpId,
          ...this.searchForm,
          firstClassId: this.equipRow.firstClassId,
          secondClassId: this.equipRow.secondClassId,
          needInstall: this.equipRow.customerSource,
          thirdClassId: this.equipRow.thirdClassId
        });
        //客户名下设备
        this.custormerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.custormerEquipList.map(item => {
          item.equipNo = item.equipCode || item.equipNo;
          return item;
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //灰度获取工程师名下设备
    async queryEngineerAllEquipReq() {
      try {
        this.dialogLoading = true;
        let res = await queryEngineerAllEquip({
          ...this.searchForm,
          companyNo: this.basicInfo.companyNo,
          originalMaterialNo: this.equipRow.materialNo,
          equipCode: this.searchForm.equipNameOrDeviceNumber,
          employeeId: this.basicInfo.engineerrpId,
          isUsed: 0
        });
        //工程师名下设备
        this.engineerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.engineerEquipList;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //灰度获取客户名下设备
    async getClientAllEquipReq() {
      try {
        this.dialogLoading = true;
        let res = await getClientAllEquip({
          ...this.searchForm,
          materialNo: this.equipRow.materialNo,
          jobId: this.basicInfo.itemId,
          gpsno: this.searchForm.equipNameOrDeviceNumber
        });
        //客户名下设备
        this.custormerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.custormerEquipList.map(item => {
          item.equipNo = item.equipCode || item.equipNo;
          return item;
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //处理请求
    queryList() {
      let equSource;
      //寄装、主机、e6yun
      if (
        this.basicInfo.isDeliverInstall == 2 &&
        this.equipRow.equType == 1 &&
        this.basicInfo.sourceOrganization == 1
      ) {
        equSource = 1;
        this.getClientAllEquipReq();
      }
      //寄装、配件(客户名下设备)、e6yun
      if (
        this.basicInfo.isDeliverInstall == 2 &&
        this.equipRow.equType != 1 &&
        this.basicInfo.sourceOrganization == 1
      ) {
        equSource = 1;
        this.getCustormerEquipReq();
      }
      //寄装、主机、g7s
      if (
        this.basicInfo.isDeliverInstall == 2 &&
        this.basicInfo.sourceOrganization == 2 &&
        this.equipRow.equType == 1
      ) {
        equSource = 1;
        this.getClientAllEquipReq();
      }
      //送装（工程师名下设备）
      if (this.basicInfo.isDeliverInstall != 2) {
        equSource = 2;
        this.queryEngineerAllEquipReq();
      }
      this.equSource = equSource;
    },
    // 表格勾选时间
    handleDeleteionChange(columns) {
      this.selColumn = columns;
    },
    select(selection) {
      if (selection.length > 1) {
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    //点击确定
    handleConfirm() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选设备");
        return;
      }
      let equSource = this.equSource;
      this.$emit("updateEquip", {
        ...this.selColumn[0],
        equSource
      });
      this.handleClose();
    },

    //重置数据
    resetData() {
      this.selColumn = [];
      this.searchForm = {
        equipCode: "", //设备编号
        equipNameOrDeviceNumber: "",
        materialNo: "",
        materialNos: [],
        equipSource: 2,
        pageIndex: 1,
        pageSize: 10
      };
      this.$refs.multipleTable.clearSelection();
      this.$refs.searchForm.resetFields();
    },
    handleClose() {
      this.resetData();
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped></style>
