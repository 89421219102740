/*
 * @Description: 审核混入
 * @Author: ChenXueLin
 * @Date: 2021-12-06 15:58:23
 * @LastEditTime: 2022-10-13 10:48:53
 * @LastEditors: ChenXueLin
 */
import { getInstallDetail, getCredential, itemReturn, downFile } from "@/api";
import { printError } from "@/utils/util";
export default {
  data() {
    return {
      imgList: [],
      equipDetailTableData: []
    };
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  methods: {
    //查询任务项设备安装详情列表
    async getInstallDetail() {
      try {
        this.loading = true;
        let res = await getInstallDetail({ itemId: this.basicInfo.itemId });
        let equipDetailTableData = res.data;
        equipDetailTableData.map((item, index) => {
          item.equSource =
            !item.equSource && item.equSource != 0 ? "" : item.equSource;
          item.equipCode = item.equipCode ? item.equipCode : "";
          item.simNo = item.simNo ? item.simNo : "";
          item.newSimNo = "";
          item.bindSimNo = item.simNo;
          item.bindSimId = item.simId;
          item.bindIsSelfOwn = item.isSelfOwn;
          item.newEquipCode = item.newEquipCode ? item.newEquipCode : "";
          item.newCardNo = item.newCardNo ? item.newCardNo : "";
          item.priceFrom = item.priceFrom ? item.priceFrom : "";
          item.fixMoney = item.fixMoney ? item.fixMoney : "";
          item.fixCatId = item.fixCatId ? item.fixCatId : "";
          item.commids = item.commids ? item.commids : "";
          item.insbodyName = item.insbodyName ? item.insbodyName : "";
          item.insbody = item.insbody ? item.insbody : "";
          item.equipOp = item.equipOp ? item.equipOp : "0";
          item.cardOp = item.cardOp ? item.cardOp : "0";
          item.dismantleStatus = item.dismantleStatus
            ? item.dismantleStatus + ""
            : "";
          item.positionList = [];
          if (
            item.insbodyName &&
            [1, 2, 7, 19, 20].indexOf(this.basicInfo.taskSecondClass) > -1
          ) {
            this.getPosition(item, index);
          }
        });
        this.equipDetailTableData = _.cloneDeep(equipDetailTableData);
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询凭证
    async getCredentialReq() {
      try {
        this.loading = true;
        let res = await getCredential({ itemId: this.basicInfo.itemId });
        let photoLocationListRespVOList = res.data.photoLocationListRespVOList;
        let imgList = [];
        for (let i = 0; i < photoLocationListRespVOList.length; i++) {
          imgList = imgList.concat(
            photoLocationListRespVOList[i].photoLocationList
          );
        }
        this.imgList = imgList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //上传成功
    handleAvatarSuccess(res, currentUploadPic) {
      console.log(res, currentUploadPic, "-----");
      let id = res.data[0].fileId;
      // 获取图片地址
      this.downFile(id, currentUploadPic);
    },
    // 获取文件地址
    async downFile(id, currentUploadPic) {
      try {
        let res = await downFile({ id });
        currentUploadPic.fileId = id;
        currentUploadPic.fileUrl = res.data.fileUrl;
      } catch (error) {
        printError(error);
      }
    },
    sendBack() {
      this.$confirm("确认是否将任务项退回到未分配状态", "确认提交", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.sendBackReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //回到未分配状态
    async sendBackReq() {
      try {
        this.loading = true;
        let res = await itemReturn({
          itemId: this.basicInfo.itemId
        });
        if (res.code == "OK") {
          this.$message.success("退回成功");
          this.handleClose();
          this.$emit("refresh");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
